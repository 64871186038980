@media all and (min-width: 480px) {
    .Login {
        padding-top: 60px;
        width: 100%;
        display: 'block'; // Fix IE 11 issue.
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .Login form {
        margin: 0 auto;
        max-width: 320px;
    }
}
