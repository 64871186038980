// make your customizations to the theme

$theme-colors: (
    'secondary': #6d63a1,
    'primary': #8346ad
);

// import bootstrap stylesheet

@import '~bootstrap/scss/bootstrap';
